import { BlocksProps, createBlockMeta } from '@/lib/parsers/blocks';
import BlocksInner from './Blocks.Inner';
import BlocksContextProvider, { BlocksContextProviderProps } from './Blocks.Provider';
import { BlockProvider } from './useBlock';

type BlocksOwnType = BlocksProps & BlocksContextProviderProps;

export default function Blocks({ blocks = [], ...context }: BlocksOwnType) {
  if (!blocks) return null;

  const sanitisedBlocks = createBlockMeta(blocks) ?? blocks;

  const blocksOutput = sanitisedBlocks.map((v, i) => (
    <BlockProvider key={i + 1} value={v}>
      <BlocksInner {...v} />
    </BlockProvider>
  ));

  return sanitisedBlocks.length ? (
    <BlocksContextProvider {...context}>{blocksOutput}</BlocksContextProvider>
  ) : (
    <></>
  );
}

'use client';

import Txt, { TxtProps } from '../ui/Txt';

export type PageTitleProps<D extends React.ElementType = 'h1'> = TxtProps<
  D,
  { titleComponent?: React.ElementType }
>;

export default function PageTitle({ children, titleComponent, ...props }: PageTitleProps) {
  return (
    children && (
      <Txt as={titleComponent || 'h1'} variant="h1" {...props}>
        {children}
      </Txt>
    )
  );
}

import { COMMON_LINK_FRAGMENT } from '@/gql/fragments/common.gql';
import { createFragmentParser } from '@liquorice/gql-utils';
import { parseUri, toString } from '@liquorice/utils';
import { CommonLinkFragment } from '__generated__/graphql';

export type CommonLink = {
  id: ID;
  title: string;
  uri: string | null;
};

export const parseCommonLinkFragment = createFragmentParser(COMMON_LINK_FRAGMENT, (data) => {
  return parseCommonLink(data);
});

export const parseCommonLink = (data: Maybe<CommonLinkFragment>): CommonLink => {
  return {
    id: toString(data?.id),
    title: toString(data?.title),
    uri: parseUri(data?.uri) ?? null,
  };
};

import { gql } from '__generated__';

export const BLOCK_BASE_FRAGMENT = gql(`
  fragment blockBase on NeoBlockInterface {
    id
    __typename
    level
  }
`);

export const BLOCKS_FRAGMENT = gql(`
  fragment blocks on blocks_NeoField {
    ...blockBase
    ...richTextBlock
    ...accordionBlock
    ...quoteCarouselBlock
    ...dividerBlock

    # Event fragments
    ...categoryTypeBlock
    ...eventIndex
    ...eventSearch
    ...featuredEvent
    ...eventCreditsBlock

    # Media fragments
    ...imageAndTextBlock
    ...logoAndTextBlock
    ...imageBlock
    ...imageCarouselBlock
    ...videoBlock
    ...fileDownloadBlock
    ...formBlock

    # Related
    ...relatedNewsBlock
    ...relatedEventsBlock

    # Cards
    ...entryCardsBlock
    ...expandingCardsBlock
    ...overviewCardsBlock
  }
`);

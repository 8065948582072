'use client';

import Grid, { GridColProps } from '../ui/Grid';
import Txt, { TxtProps } from '../ui/Txt';
import { PageHeaderRootProps } from './PageHeaderRoot';

export type PageSummaryProps = TxtProps<
  'span',
  {
    GridColProps?: GridColProps;
    useGridCol?: boolean;
  }
> &
  Partial<PageHeaderRootProps>;

export default function PageSummary({
  children,
  GridColProps,
  useGridCol = true,
  ...props
}: PageSummaryProps) {
  const content = (
    <Txt as="span" variant="large" {...props}>
      {children}
    </Txt>
  );

  return useGridCol && children ? (
    <Grid.Col md={8} {...GridColProps}>
      {content}
    </Grid.Col>
  ) : (
    content
  );
}

'use client';

import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { EVENT_INDEX_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { withFragment } from '@liquorice/gql-utils';
import { getFragmentData } from '__generated__';
import Breadcrumbs from '../Breadcrumbs';
import PageHeaderRoot from './PageHeaderRoot';
import PageTitle from './PageTitle';

const PageHeaderEventIndex = withFragment(EVENT_INDEX_CARD_FRAGMENT, (data) => {
  if (!data) return null;

  const base = getFragmentData(ENTRY_BASE_FRAGMENT, data);

  return (
    <PageHeaderRoot variant="index" colorSet="primaryAlt" paper>
      <Breadcrumbs data={base} CtaButtonProps={{ color: 'secondary' }} cx={{ mB: 'xs' }} />
      <PageTitle bodyFont weight="bold" uppercase>
        {data.entryTitle}
      </PageTitle>
    </PageHeaderRoot>
  );
});

export default PageHeaderEventIndex;

import BlockHeader from '@/components/BlockHeader';
import Carousel from '@/components/Carousel';
import CtaButton from '@/components/CtaButton';
import EventCard from '@/components/EventCard';
import SkeletonCards from '@/components/SkeletonCards';
import Txt from '@/components/ui/Txt';
import {
  ACCESSIBILITY_CATEGORY_FRAGMENT,
  EVENT_TYPE_CATEGORY_FRAGMENT,
  GENRE_CATEGORY_FRAGMENT,
  PROGRAM_CATEGORY_FRAGMENT,
  VENUE_CATEGORY_FRAGMENT,
} from '@/gql/fragments/categories.gql';
import { useEventIndexEntries } from '@/lib/getters/useEventIndexEntries';
import { createBlock } from '@/lib/parsers/blocks';
import { parseDateRange } from '@/lib/parsers/common';
import { useAppContext } from '@/lib/store/hooks';
import { simplifyCategory } from '@/lib/utils/format';
import { useBreakpointMin } from '@/theme';
import { parseUri, toBoolean } from '@liquorice/utils';
import { gql } from '__generated__';
import { useTranslations } from 'next-intl';
import { BlockContainer } from '../..';

export const EVENT_INDEX_BLOCK_FRAGMENT = gql(`
  fragment eventIndex on blocks_eventIndex_BlockType {
    heading

    # Filters
    latestEvents
    dateRange {
      end
      isFuture
      isNotPast
      isOnGoing
      isPast
      start
    }
    venueCategory {
      ...categories
    }
    programCategory {
      ...categories
    }
    accessibilityCategory {
      ...categories
    }
    genreCategory {
      ...categories
    }
    eventTypeCategory {
      ...categories
    }
    includeEventIndex: lightswitch
  }
`);

export const EventIndexBlock = createBlock(EVENT_INDEX_BLOCK_FRAGMENT, ({ data, meta }) => {
  const {
    venueCategory: venue,
    programCategory: program,
    accessibilityCategory: accessibility,
    eventTypeCategory: eventType,
    genreCategory: genre,
    dateRange: range,
    latestEvents,
    heading,
    includeEventIndex,
  } = data;

  const t = useTranslations();
  const genreCategory = simplifyCategory(GENRE_CATEGORY_FRAGMENT, genre);
  const venueCategory = simplifyCategory(VENUE_CATEGORY_FRAGMENT, venue);
  const programCategory = simplifyCategory(PROGRAM_CATEGORY_FRAGMENT, program);
  const accessibilityCategory = simplifyCategory(ACCESSIBILITY_CATEGORY_FRAGMENT, accessibility);
  const eventTypeCategory = simplifyCategory(EVENT_TYPE_CATEGORY_FRAGMENT, eventType);
  const dateRange = parseDateRange(range);
  const useCurrentDate = toBoolean(latestEvents);
  const { commonLinks } = useAppContext() ?? {};
  const eventIndexUri = parseUri(commonLinks?.eventIndex.uri);
  const includeLink = toBoolean(includeEventIndex);

  const { items, loading, error } =
    useEventIndexEntries(
      {
        genreCategory,
        venueCategory,
        programCategory,
        accessibilityCategory,
        eventTypeCategory,
      },
      dateRange,
      useCurrentDate
    ) ?? {};

  const hasResults = items?.length && (items?.length ?? 0) > 0;

  const md = useBreakpointMin('md');

  if (error) return null;

  return (
    <BlockContainer
      {...{
        colorSet: 'white',
        paper: true,
        meta,
        marginY: true,
        disableTransition: true,
        overflow: true,
        customAnchor: heading,
      }}>
      <BlockHeader
        maxWidth="none"
        heading={heading}
        HeadingProps={{ variant: 'h3', cx: { m: 'none' } }}
        cx={{ mB: 'md' }}
        direction="row"
        alignItems="center"
        justifyContent="between">
        {eventIndexUri && includeLink && (
          <CtaButton href={eventIndexUri} underline="always">
            {t('actions.viewMore')}
          </CtaButton>
        )}
      </BlockHeader>
      {loading ? (
        <SkeletonCards
          amount={md ? 3 : 1}
          md={6}
          lg={4}
          SkeletonItems={{
            items: [
              { variant: 'rounded', height: 200 },
              { variant: 'rounded', height: 30, width: '90%' },
              { variant: 'rounded', height: 15, width: '50%' },
            ],
          }}
        />
      ) : hasResults ? (
        <Carousel
          slidesPerView={md ? 3 : 1}
          slidesPerGroup={md ? 3 : 1}
          spaceBetween={24}
          items={items?.map((v, i) => (
            <EventCard key={i} {...v} />
          ))}
        />
      ) : (
        <Txt>{t('search.noResults', { query: heading })}</Txt>
      )}
    </BlockContainer>
  );
});

export default EventIndexBlock;

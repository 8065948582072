import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { parseEventTimeFragment } from '@/lib/parsers/common/parseEventTime';
import { fmtEventTime } from '@/lib/utils/format';
import { parseUri } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { EventCardFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Container from '../ui/Container';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './PageHeader.css';
import PageMedia from './PageMedia';

const PageEvent = (props: EventCardFragment) => {
  const { entryTitle, entrySubTitle, eventTime, entryImage, videoUrl } = props;
  const { uri } = getFragmentData(ENTRY_BASE_FRAGMENT, props) ?? {};
  const time = fmtEventTime(parseEventTimeFragment(eventTime));

  const href = parseUri(uri);
  const t = useTranslations('actions');

  return (
    <Container className={styles.homeRoot}>
      <Box className={styles.imageWrapper}>
        <PageMedia ImageProps={{ data: entryImage, fixedRatio: true }} VideoProps={{ videoUrl }} />
      </Box>
      <Flex rowGap="md">
        {entryTitle && (
          <Txt variant="h1" color="white" monoFont cx={{ mB: 'none' }}>
            {entryTitle}
          </Txt>
        )}
        <Flex>
          {entrySubTitle && (
            <Txt color="white" cx={{ fontFamily: 'mono', mB: 'none' }}>
              {entrySubTitle}
            </Txt>
          )}
          {time && (
            <Txt color="white" cx={{ fontFamily: 'mono', mB: 'none' }}>
              {time}
            </Txt>
          )}
        </Flex>
        {href && (
          <Flex direction="row">
            <Btn
              color="white"
              variant="outlined"
              size="large"
              href={href}
              className={styles.homeCta}>
              <Txt uppercase weight="bold">
                {t('buyTickets')}
              </Txt>
            </Btn>
          </Flex>
        )}
      </Flex>
    </Container>
  );
};

export default PageEvent;

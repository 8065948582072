import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { EVENT_INDEX_QUERY, EVENT_TIME_QUERY } from '@/gql/queries/eventIndexQueries.gql';
import { useQuery } from '@apollo/client';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { makeNonNullableArray } from '@liquorice/utils';
import {
  EventIndexEntriesQueryVariables,
  EventTimeEntriesQueryVariables,
} from '__generated__/graphql';
import { DateRange } from '../parsers/common';
import { parseEventTimeFragment } from '../parsers/common/parseEventTime';
import { toIsoString } from '../utils/format';

export const useEventTimeEntries = (variables: EventTimeEntriesQueryVariables) => {
  const { data, loading, error } = useQuery(EVENT_TIME_QUERY, {
    variables,
  });
  if (error) return null;

  const timeFieldEntries = data?.eventTimeFieldEntries;

  const result = parseEventTimeFragment(timeFieldEntries);

  return {
    result,
    loading,
    error,
  };
};

export const useEventIndexEntries = (
  variables: EventIndexEntriesQueryVariables,
  dateRange?: DateRange,
  useCurrentDate?: boolean
) => {
  let id: number | number[] | undefined = undefined;
  let date: string[] | undefined = undefined;
  const and = 'and';

  const fmtDateVar = (start?: string, end?: string) => {
    if (!start && !end) return undefined;
    if (start && !end) return [and, `>=${start}`];
    if (!start && end) return [and, `<=${end}`];
    return [and, `>=${start}`, `<=${end}`];
  };

  const { end, start } = dateRange ?? {};
  const currentDate = new Date();

  if (useCurrentDate) {
    date = fmtDateVar(toIsoString(currentDate));
  } else {
    date = fmtDateVar(start, end);
  }

  // Get time fields relevant to the event
  const timeFields = useEventTimeEntries({ date });

  // Reduce entries down to ownerIds
  if (!!timeFields?.result?.length && !timeFields.loading) {
    id = makeNonNullableArray(timeFields?.result?.map((entry) => entry?.ownerId));
  }

  // Get the event entries
  const { data, loading, error } = useQuery(EVENT_INDEX_QUERY, {
    variables: { id, ...variables },
  });

  if (error) return null;

  const entryParser = createFragmentArrayParser(EVENT_CARD_FRAGMENT, (data) => data);

  const items = entryParser(data?.eventEntries);

  // No access to eventTime obj within GraphQL query
  items?.sort((a, b) => {
    const dateA = parseEventTimeFragment(a.eventTime)[0]?.date || '';
    const dateB = parseEventTimeFragment(b.eventTime)[0]?.date || '';
    return new Date(dateA).getTime() - new Date(dateB).getTime();
  });

  return {
    items,
    loading,
    error,
  };
};

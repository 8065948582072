import Carousel from '@/components/Carousel';
import { createBlock } from '@/lib/parsers/blocks';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';
import QuoteBlock from '../QuoteBlock';

export const QUOTE_CAROUSEL_BLOCK_FRAGMENT = gql(`
  fragment quoteCarouselBlock on blocks_quoteCarousel_BlockType {
    quoteItem {
      ...quoteBlock
    }
  }
`);

const QuoteCarouselBlock = createBlock(QUOTE_CAROUSEL_BLOCK_FRAGMENT, ({ data, meta }) => {
  const items = excludeEmptyTypedElements(data.quoteItem);
  const isQuoteCarousel = items.length > 1;

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
      }}>
      {!isQuoteCarousel ? (
        items?.map((v, i: number) => <QuoteBlock key={i} data={v} />)
      ) : (
        <Carousel
          disableNavigation
          style={{ cursor: 'grab' }}
          items={items?.map((v, i: number) => (
            <QuoteBlock key={i} data={v} />
          ))}
        />
      )}
    </BlockContainer>
  );
});

export default QuoteCarouselBlock;

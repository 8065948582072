'use client';

import Grid, { GridColProps } from '../ui/Grid';
import Txt, { TxtProps } from '../ui/Txt';

export type PageSubTitleProps = TxtProps<
  'span',
  {
    GridColProps?: GridColProps;
    useGridCol?: boolean;
  }
>;

export default function PageSubTitle({
  children,
  GridColProps,
  useGridCol = true,
  ...props
}: PageSubTitleProps) {
  const inner = (
    <Txt as="span" variant="large" {...props}>
      {children}
    </Txt>
  );

  return useGridCol && children ? (
    <Grid.Col md={8} {...GridColProps}>
      {inner}
    </Grid.Col>
  ) : (
    inner
  );
}

'use client';

import { useEntryIndexSearchForm } from '@/components/EntryIndex/EntryIndex.SearchForm';
import { useEntryIndex } from '@/components/EntryIndex/useEntryIndex';
import { useEntryIndexQueryHandler } from '@/components/EntryIndex/useEntryIndexQueryHandler';
import useEntryIndexSubmitButton from '@/components/EntryIndex/useEntryIndexSubmitButton';
import IconButton from '@/components/IconButton';
import Box from '@/components/ui/Box';
import { BtnProps } from '@/components/ui/Btn';
import { useTranslations } from 'next-intl';
import React from 'react';
import * as styles from './SearchBar.css';

const SearchBarInput = () => {
  const t = useTranslations('search');
  const placeholder = t('placeholder');
  const { inputProps, /* searchSummary, */ formProps } = useEntryIndexSearchForm();
  const submitButtonProps = useEntryIndexSubmitButton();
  const setSearchValue = useEntryIndex((s) => s.setSearch);
  const searchValue = useEntryIndex((s) => s.search);
  const setLoading = useEntryIndex((s) => s.setLoading);
  const clearItems = useEntryIndex((s) => () => s.setItems([]));
  // const [inputValue, setInputValue] = React.useState<string>('');
  const { triggerQuery } = useEntryIndexQueryHandler();

  // Create a 0.5s delay before updating the search value to wait for the user to finish typing
  const [timer, setTimer] = React.useState<NodeJS.Timeout | null>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  React.useEffect(() => {
    // if ((searchValue || '') === inputValue) return; // Prevent infinite loop
    const query = searchValue || '';
    if (timer) clearTimeout(timer);

    if (query.length === 0) {
      clearItems();
      return;
    }

    if (query.length < 3) return; // Wait for at least 3 characters

    const newTimer = setTimeout(() => {
      triggerQuery(true);
    }, 500);

    // Set a new timeout
    setTimer(newTimer);
    setLoading(true);
    return () => {
      if (timer) {
        clearTimeout(timer);
        setLoading(false);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <Box as="form" {...formProps} className={styles.inputWrap}>
      <Box
        as="input"
        cx={{ fontSize: 'large' }}
        {...inputProps}
        onChange={handleChange}
        value={searchValue || ''}
        className={styles.input}
        placeholder={placeholder}
      />
      <IconButton
        icon="search"
        className={styles.iconButton}
        {...(submitButtonProps as BtnProps)}
        variant="icon"
      />
    </Box>
  );
};

export default SearchBarInput;

import { CardProps } from '@/components/Card';
import { useEntryCards } from '@/components/EntryCards/useEntryCards';
import { ENTRY_CARDS_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { parseLinkField } from '@/lib/parsers/common';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { createFragmentParser } from '@liquorice/gql-utils';
import { firstNonNullable, toStringOrNull } from '@liquorice/utils';
import { SimpleCardBlockFragment } from '__generated__/graphql';

/** Hook to apply card "overrides" and extra features */
export const useSimpleCard = ({
  entrySingle,
  heading,
  content,
  linkField,
}: SimpleCardBlockFragment) => {
  const entry = firstNonNullable(entrySingle);
  const parseEntryCards = createFragmentParser(ENTRY_CARDS_FRAGMENT, (data) => data);

  const { fragment } = useEntryCards(parseEntryCards(entry)) ?? {};
  const maybeHeading = toStringOrNull(heading);
  const maybeContent = cleanHtml(content);
  const maybeLink = parseLinkField(linkField);
  // const maybeColorScheme = parseColorScheme(colorScheme);

  // Bail early
  if (!fragment) return null;

  const isFAQ = fragment?.__typename === 'faq_Entry';

  const title = isFAQ ? fragment.heading : maybeHeading ?? fragment?.entryTitle;
  const entrySummary = isFAQ ? fragment.content : maybeContent ?? fragment?.entrySummary;

  const item = {
    title,
    entrySummary,
    uri: maybeLink?.href,
  };

  return { item } as CardProps;
};

import Divider from '@/components/ui/Divider';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';

export const DIVIDER_BLOCK_FRAGMENT = gql(`
  fragment dividerBlock on blocks_divider_BlockType {
    __typename
  }
`);

export const DividerBlock = createBlock(DIVIDER_BLOCK_FRAGMENT, ({ meta }) => {
  return (
    <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
      <Divider cx={{ color: 'primaryTintLight' }} />
    </BlockContainer>
  );
});

export default DividerBlock;

'use client';

import Box, { BoxProps } from '../ui/Box';
import Txt, { TxtProps } from '../ui/Txt';

type PageDateProps = BoxProps<'div', { TxtProps?: TxtProps }>;

export default function PageDate({ children, TxtProps, ...props }: PageDateProps) {
  if (!children) return null;

  return (
    <Box {...props}>
      <Txt as="span" variant="body" {...TxtProps}>
        {children}
      </Txt>
    </Box>
  );
}

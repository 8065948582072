'use client';

import { PROGRAM_CATEGORY_FRAGMENT } from '@/gql/fragments/categories.gql';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { useColorScheme } from '@/lib/parsers/common';
import { parseEventTimeFragment } from '@/lib/parsers/common/parseEventTime';
import { fmtEventTime } from '@/lib/utils/format';
import { useBreakpointMax } from '@/theme';
import { createFragmentParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, firstNonNullable, parseUri } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { EventCardFragment } from '__generated__/graphql';
import { useTranslations } from 'next-intl';
import Image from '../Image';
import PageTicket from '../PageTicket';
import Box from '../ui/Box';
import Btn from '../ui/Btn';
import Container from '../ui/Container';
import Flex from '../ui/Flex';
import Txt from '../ui/Txt';
import * as styles from './FeaturedCard.css';

const FeaturedEventCard = (props: EventCardFragment) => {
  const t = useTranslations();
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, props);

  const { uri, postDate } = base;
  const { entryTitle, eventTime, entryImage, programCategory } = props;
  const date = fmtEventTime(parseEventTimeFragment(eventTime)) ?? postDate;
  const href = parseUri(uri);

  const parseProgramCategory = createFragmentParser(PROGRAM_CATEGORY_FRAGMENT, (data) => data);
  const program = parseProgramCategory(
    firstNonNullable(excludeEmptyTypedElements(programCategory))
  );

  const { colorScheme, oppositeScheme } = useColorScheme(program?.colorScheme);

  const md = useBreakpointMax('md');
  const sm = useBreakpointMax('sm');

  return (
    <Box cx={{ position: 'relative' }}>
      <Box className={styles.imageWrapper}>
        <Image className={styles.image} alt="featured-image" fixedRatio data={entryImage} />
      </Box>
      <Box className={styles.contentWrapper}>
        <Container maxWidth="xl" disablePadding={sm}>
          <Box
            className={styles.content({ variant: 'event' })}
            cx={{ p: 'sm', mT: 'md' }}
            colorSet={colorScheme}
            paper>
            {program?.title && (
              <Txt as="p" variant="tiny" uppercase monoFont>
                {program?.title}
              </Txt>
            )}
            {entryTitle && (
              <Txt as="p" variant="large" weight="bold">
                {entryTitle}
              </Txt>
            )}
            {date && (
              <Txt as="p" uppercase cx={{ fontFamily: 'mono', fontSize: 'small' }}>
                {date}
              </Txt>
            )}
            <Flex columnGap="xs" rowGap="xs" direction={md ? 'row' : 'column'}>
              {href && (
                <Btn
                  href={href}
                  variant="outlined"
                  color={oppositeScheme}
                  weight="bold"
                  rounded="small"
                  fullWidth={true}>
                  {t('common.moreInfo')}
                </Btn>
              )}
              <PageTicket
                TicketBtnProps={{
                  weight: 'bold',
                  variant: 'filled',
                  color: oppositeScheme,
                  rounded: 'small',
                  fullWidth: true,
                  startIcon: 'ticket',
                }}
                parentEvent={props}
              />
            </Flex>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default FeaturedEventCard;

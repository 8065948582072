import {
  ARTICLE_ENTRY_FRAGMENT,
  ARTICLE_INDEX_ENTRY_FRAGMENT,
  EVENT_ENTRY_FRAGMENT,
  HOME_ENTRY_FRAGMENT,
  PAGE_EXPANDABLE_ENTRY_FRAGMENT,
  PAGE_OVERVIEW_ENTRY_FRAGMENT,
  PAGE_STANDARD_ENTRY_FRAGMENT,
  VENUE_ENTRY_FRAGMENT,
} from '@/gql/fragments/entries.gql';
import { getFragmentData } from '__generated__';
import { EntriesFragment } from '__generated__/graphql';

type Entries = EntriesFragment | null | undefined;

export const useEntries = (data: Entries) => {
  let entry;
  let typeLabel;

  if (!data) return null;

  switch (data?.__typename) {
    case 'article_Entry':
      entry = getFragmentData(ARTICLE_ENTRY_FRAGMENT, data);
      typeLabel = 'Article';
      break;
    case 'event_Entry':
      entry = getFragmentData(EVENT_ENTRY_FRAGMENT, data);
      typeLabel = 'Event';
      break;
    case 'expandable_Entry':
      entry = getFragmentData(PAGE_EXPANDABLE_ENTRY_FRAGMENT, data);
      typeLabel = 'Expandable';
      break;
    case 'home_Entry':
      entry = getFragmentData(HOME_ENTRY_FRAGMENT, data);
      typeLabel = 'Home';
      break;
    case 'index_Entry':
      entry = getFragmentData(ARTICLE_INDEX_ENTRY_FRAGMENT, data);
      typeLabel = 'Index';
      break;
    case 'overview_Entry':
      entry = getFragmentData(PAGE_OVERVIEW_ENTRY_FRAGMENT, data);
      typeLabel = 'Overview';
      break;
    case 'standard_Entry':
      entry = getFragmentData(PAGE_STANDARD_ENTRY_FRAGMENT, data);
      typeLabel = 'Standard';
      break;
    case 'venue_Entry':
      entry = getFragmentData(VENUE_ENTRY_FRAGMENT, data);
      typeLabel = 'Venue';
      break;

    default:
      break;
  }

  return { entry, typeLabel };
};

export type EntriesReturnType = ReturnType<typeof useEntries>;

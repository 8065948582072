import EntryIndexProvider from '@/components/EntryIndex/EntryIndex.Provider';
import SearchBarInput from '@/components/SearchBar/SearchBar.Input';
import SearchBarResults from '@/components/SearchBar/SearchBar.Results';
import { SEARCHABLE_ENTRY_TYPES } from '@/lib/constants';
import { Suspense } from 'react';
import * as styles from './SearchBar.css';
import Box, { BoxProps } from '../ui/Box';

export type SearchBarProps = BoxProps<
  'div',
  {
    /**/
    redirect: string;
    searchableEntryTypes?: string[] | string;
  }
>;

const SearchBar = ({ searchableEntryTypes, redirect, ...props }: SearchBarProps) => {
  return (
    <Suspense>
      <EntryIndexProvider
        perPage={5}
        baseEntryQuery={{
          section: searchableEntryTypes ?? SEARCHABLE_ENTRY_TYPES,
        }}
        filtersConfig={[]}
        autoSubmit={false}
        skipInitialQuery>
        <Box className={styles.root} {...props}>
          <Box className={styles.inner}>
            <SearchBarInput />
            <SearchBarResults redirect={redirect} />
          </Box>
        </Box>
      </EntryIndexProvider>
    </Suspense>
  );
};

export default SearchBar;

import Icon from '@/components/Icon';
import NextAnchor from '@/components/ui/NextAnchor';
import Txt from '@/components/ui/Txt';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { ENTRY_CARDS_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createFragmentParser } from '@liquorice/gql-utils';
import { parseUri } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { useEntryCards } from '../EntryCards/useEntryCards';
import { EntryResultItem } from '../EntryIndex/entryIndexTypes';
import * as styles from './SearchBar.css';

type SearchBarResultProps = {
  item: EntryResultItem;
};

const SearchBarResult = (props: SearchBarResultProps) => {
  const { item } = props;

  const parseEntryCards = createFragmentParser(ENTRY_CARDS_FRAGMENT, (data) => data);
  const entryCard = parseEntryCards(item);
  const { fragment } = useEntryCards(entryCard) ?? {};
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, fragment);

  const isFAQ = fragment?.__typename === 'faq_Entry';

  const title = isFAQ ? base?.title : fragment?.entryTitle;
  const uri = parseUri(base?.uri);

  const parentTitle = base?.parent?.title ?? '';

  let typeLabel = '';

  switch (true) {
    case base?.typeHandle === 'article':
      typeLabel = 'Article';
      break;
    case base?.typeHandle === 'event':
      typeLabel = 'Event';
      break;
    case base?.typeHandle === 'faqIndex':
      typeLabel = 'FAQ';
      break;
    default:
      typeLabel = 'Page';
      break;
  }

  return (
    uri && (
      <NextAnchor href={uri} className={styles.resultItem}>
        <Txt variant="large" className={styles.resultItemLabel}>
          {parentTitle && <span>{parentTitle} / </span>}
          {title}
        </Txt>
        <Txt variant="large" className={styles.resultItemType}>
          {typeLabel}
        </Txt>
        <Icon className={styles.resultItemIcon} name="chevronRight" />
      </NextAnchor>
    )
  );
};

export default SearchBarResult;

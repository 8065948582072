import FeaturedEventCard from '@/components/FeaturedCard/FeaturedCard.Event';
import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createBlock } from '@/lib/parsers/blocks';
import { createFragmentParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements, firstNonNullable } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const FEATURED_EVENT_BLOCK_FRAGMENT = gql(`
  fragment featuredEvent on blocks_featuredEvent_BlockType {
    eventSingle {
      ...eventCard
    }
  }
`);

export const FeaturedEventBlock = createBlock(FEATURED_EVENT_BLOCK_FRAGMENT, ({ data, meta }) => {
  const card = excludeEmptyTypedElements(data.eventSingle);
  const parseEvent = createFragmentParser(EVENT_CARD_FRAGMENT, (data) => data);
  const event = parseEvent(firstNonNullable(card));

  if (!event) return null;

  return (
    <BlockContainer
      {...{
        meta,
        maxWidth: 'mx',
        marginY: 'none',
        disableTransition: true,
        ContainerProps: { disablePadding: true },
      }}>
      <FeaturedEventCard {...event} />
    </BlockContainer>
  );
});

export default FeaturedEventBlock;

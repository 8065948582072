import { gql } from '__generated__';

export const EXTERNAL_CARD_BLOCK_FRAGMENT = gql(`
  fragment externalCardBlock on externalCard_Entry {  
    heading
    content: htmlContentSimple
    linkField {
      ...linkField
    }
    imageSingle {
      ...image
    }
    colorScheme {
      label
    }
  }
`);

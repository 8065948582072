'use client';

// import { useViewColorScheme } from '@/lib/store/hooks';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { PAGE_EXPANDABLE_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { useColorScheme } from '@/lib/parsers/common';
import { useBreakpointMin } from '@/theme';
import { withFragment } from '@liquorice/gql-utils';
import { getFragmentData } from '__generated__';
import Breadcrumbs from '../Breadcrumbs';
import Grid from '../ui/Grid';
import PageHeaderRoot from './PageHeaderRoot';
import PageImage from './PageImage';
import PageTitle from './PageTitle';

const PageHeaderExpandable = withFragment(PAGE_EXPANDABLE_CARD_FRAGMENT, (data) => {
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, data);
  const sm = useBreakpointMin('sm');

  const { colorScheme, oppositeScheme } = useColorScheme(data?.colorScheme);

  if (!data) return null;

  return (
    <PageHeaderRoot
      variant="overview"
      colorSet={colorScheme}
      paper
      GridColProps={{ md: 8 }}
      afterCol={
        sm && (
          <Grid.Col offsetMd={1} sm={5} md={3}>
            <PageImage mask={true} data={data.entryImage} />
          </Grid.Col>
        )
      }>
      <Breadcrumbs
        data={base}
        CtaButtonProps={{ color: oppositeScheme }}
        cx={{ mB: 'xs', mT: '5xl' }}
      />
      <PageTitle monoFont uppercase cx={{ mB: '5xl' }}>
        {data.entryTitle}
      </PageTitle>
    </PageHeaderRoot>
  );
});

export default PageHeaderExpandable;

import Card from '@/components/Card';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkField, useColorScheme } from '@/lib/parsers/common';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { toStringOrNull } from '@liquorice/utils';
import { EXTERNAL_CARD_BLOCK_FRAGMENT } from './useExternalCard';

export const ExternalCardBlockDefault = createBlock(EXTERNAL_CARD_BLOCK_FRAGMENT, ({ data }) => {
  const { heading, content, linkField, imageSingle } = data;
  const link = parseLinkField(linkField);
  const newWindow = link?.target === '_blank';
  const { oppositeScheme, colorScheme } = useColorScheme(data.colorScheme);
  const title = toStringOrNull(heading);
  const description = cleanHtml(content);

  return (
    <Card
      {...{
        colorSet: colorScheme,
        paper: true,
        fullHeight: true,
        elevation: false,
        padding: 'lg',
        item: {
          title,
          description,
          image: imageSingle,
          href: link?.href,
          customText: link?.children,
        },
        newWindow,
      }}>
      <Grid>
        <Grid.Col>
          <Card.Image mask={true} ratio="landscape" />
        </Grid.Col>
        <Grid.Col>
          <Card.Body>
            <Card.Title variant="h4" cx={{ mB: 'none' }} />
            <Card.Description color={oppositeScheme} />
            <Card.Cta variant="filled" color={oppositeScheme} rounded="small" />
          </Card.Body>
        </Grid.Col>
      </Grid>
    </Card>
  );
});

export default ExternalCardBlockDefault;

import { useEntryCards } from '@/components/EntryCards/useEntryCards';
import OverviewCard from '@/components/OverviewCard';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { ENTRY_CARDS_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkField } from '@/lib/parsers/common';
import { createFragmentParser } from '@liquorice/gql-utils';
import { firstNonNullable, maybeGet, parseUri } from '@liquorice/utils';
import { gql } from '__generated__';

export const OVERVIEW_CARD_BLOCK_FRAGMENT = gql(`
  fragment overviewCardBlock on entryCard_Entry {
    entrySingle {
      ...entryCards
    }
    
    # Override props
    heading
    content: htmlContentSimple
    linkField {
      ...linkField
    }
  }
`);

export const OverviewCardBlock = createBlock(OVERVIEW_CARD_BLOCK_FRAGMENT, ({ data }) => {
  const entry = firstNonNullable(data.entrySingle);
  const parseEntryCards = createFragmentParser(ENTRY_CARDS_FRAGMENT, (data) => data);
  const parseBase = createFragmentParser(ENTRY_BASE_FRAGMENT, (data) => data);
  const { heading: maybeTitle, content: maybeDescription, linkField: maybeLink } = data;

  const card = useEntryCards(parseEntryCards(entry));

  const { fragment } = card ?? {};

  const base = parseBase(fragment);

  const maybeHref = parseLinkField(maybeLink)?.href;
  const isFAQ = fragment?.__typename === 'faq_Entry';

  const title = isFAQ ? fragment.heading : maybeTitle ?? fragment?.entryTitle;
  const description = isFAQ ? fragment.content : maybeDescription ?? fragment?.entrySummary;
  const image = isFAQ ? null : fragment?.entryImage;

  const href = maybeHref ? maybeHref : parseUri(base?.uri);

  const maybeColorScheme = maybeGet(fragment, 'colorScheme');

  return (
    <OverviewCard
      {...{
        item: {
          title,
          description,
          href,
          image,
        },
        colorScheme: maybeColorScheme,
      }}
    />
  );
});

export default OverviewCardBlock;

'use client';

import Box from '@/components/ui/Box';
import Divider from '@/components/ui/Divider';
import Flex from '@/components/ui/Flex';
import { useAppContext } from '@/lib/store/hooks';
import { parseUri } from '@liquorice/utils';

const EventLayoutDivider = () => {
  const { commonLinks } = useAppContext() ?? {};
  const eventIndexUri = parseUri(commonLinks?.eventIndex.uri);
  // const page = useView() as Entry<'event'>;
  // const genreCategory = page.genreCategory as unknown as Category<'genreCategory'>[];

  return (
    <Box cx={{ mY: '3xl' }}>
      <Divider cx={{ color: 'primaryTintLight', mB: 'md' }} />
      <Flex direction="row" columnGap="xs">
        {/* {genreCategory.map((genre) => {
          return (
            <Btn
              key={genre.id}
              size="tiny"
              variant="filled"
              color="primaryTintLighter"
              rounded="large"
              href={`${eventIndex}?${genre.groupHandle}=${genre.id}`}>
              {genre.title}
            </Btn>
          );
        })} */}
      </Flex>
    </Box>
  );
};

export default EventLayoutDivider;

'use client';

import { EVENT_CARD_FRAGMENT, HOME_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createFragmentArrayParser, withFragment } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { A11y, Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import PageEvent from './PageEvent';
import * as styles from './PageHeader.css';
import PageHeaderRoot from './PageHeaderRoot';

const PageHeaderHome = withFragment(HOME_CARD_FRAGMENT, (data) => {
  if (!data) return null;

  const eventParser = createFragmentArrayParser(EVENT_CARD_FRAGMENT, (data) => data);
  const eventMultiple = excludeEmptyTypedElements(data.eventMultiple);

  const events = eventParser(eventMultiple);

  return (
    <PageHeaderRoot
      variant="home"
      beforeContainer={
        <Swiper
          modules={[Pagination, A11y, Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          grabCursor
          pagination={{
            clickable: true,
            horizontalClass: styles.pagination,
            bulletClass: styles.paginationBullet,
            bulletActiveClass: styles.paginationBulletActive,
          }}>
          {events.map((event) => {
            return (
              <SwiperSlide style={{ opacity: 1 }} key={event.eventId}>
                <PageEvent {...event} />
              </SwiperSlide>
            );
          })}
        </Swiper>
      }></PageHeaderRoot>
  );
});

export default PageHeaderHome;

import {
  LINK_FIELD_MULTIPLE_CUSTOM_FRAGMENT,
  LINK_FIELD_MULTIPLE_EMAIL_FRAGMENT,
  LINK_FIELD_MULTIPLE_ENTRY_FRAGMENT,
  LINK_FIELD_MULTIPLE_PHONE_FRAGMENT,
  LINK_FIELD_MULTIPLE_URL_FRAGMENT,
  LINKFIELD_MULTIPLE_FRAGMENT,
} from '@/gql/fragments/linkFieldMultiple.gql';
import { deleteUndefined, toString, toStringOrNull } from '@liquorice/allsorts-craftcms-nextjs';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { firstNonNullable } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import { LinkFieldMultipleFragment } from '__generated__/graphql';
import { Link } from './parseLinkField';

const parseLinks = (data: LinkFieldMultipleFragment) => {
  const item = firstNonNullable(data);

  let fragment = null;

  if (item?.__typename === 'linkFieldMultiple_Url_LinkType')
    fragment = getFragmentData(LINK_FIELD_MULTIPLE_URL_FRAGMENT, item);
  if (item?.__typename === 'linkFieldMultiple_Entry_LinkType')
    fragment = getFragmentData(LINK_FIELD_MULTIPLE_ENTRY_FRAGMENT, item);
  if (item?.__typename === 'linkFieldMultiple_Email_LinkType')
    fragment = getFragmentData(LINK_FIELD_MULTIPLE_EMAIL_FRAGMENT, item);
  if (item?.__typename === 'linkFieldMultiple_Phone_LinkType')
    fragment = getFragmentData(LINK_FIELD_MULTIPLE_PHONE_FRAGMENT, item);
  if (item?.__typename === 'linkFieldMultiple_Custom_LinkType')
    fragment = getFragmentData(LINK_FIELD_MULTIPLE_CUSTOM_FRAGMENT, item);

  if (!fragment) return null;

  const { target, type, text, title, url: href } = fragment;

  const download = type === 'asset';
  const defaultTarget =
    type && ['asset', 'mail', 'url', 'tel'].includes(type) ? '_blank' : undefined;

  const field: Link = {
    href: toString(href),
    target: toStringOrNull(target) ?? defaultTarget,
    children: toStringOrNull(text) ?? undefined,
    title: toStringOrNull(title) ?? undefined,
    download,
  };

  return deleteUndefined(field);
};

export const parseLinkFieldMultiple = createFragmentArrayParser(
  LINKFIELD_MULTIPLE_FRAGMENT,
  (data) => {
    if (!data) return null;

    return data.map((item) => parseLinks(item));
  }
);

import { gql } from '__generated__';

export const LINK_FIELD_MULTIPLE_URL_FRAGMENT = gql(`
  fragment linkFieldMultipleUrl on linkFieldMultiple_Url_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_MULTIPLE_ENTRY_FRAGMENT = gql(`
  fragment linkFieldMultipleEntry on linkFieldMultiple_Entry_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_MULTIPLE_CUSTOM_FRAGMENT = gql(`
  fragment linkFieldMultipleCustom on linkFieldMultiple_Custom_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_MULTIPLE_EMAIL_FRAGMENT = gql(`
  fragment linkFieldMultipleEmail on linkFieldMultiple_Email_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINK_FIELD_MULTIPLE_PHONE_FRAGMENT = gql(`
  fragment linkFieldMultiplePhone on linkFieldMultiple_Phone_LinkType {
    __typename
    target
    text
    newWindow
    title
    type
    url
  }
`);

export const LINKFIELD_MULTIPLE_FRAGMENT = gql(`
  fragment linkFieldMultiple on HyperLinkInterface {
    ...linkFieldMultipleUrl
    ...linkFieldMultipleEntry
    ...linkFieldMultipleCustom
    ...linkFieldMultipleEmail
    ...linkFieldMultiplePhone
  }
`);

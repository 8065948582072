'use client';

import { parseCommonLink } from '@/lib/parsers/common/parseCommonLinks';
import { useAppContext } from '@/lib/store/hooks';
import { isTypedFragment } from '@liquorice/gql-utils';
import { EntryBaseFragment } from '__generated__/graphql';
import CtaButton from '../CtaButton';
import Box, { BoxProps } from '../ui/Box';
import { BtnStyleProps } from '../ui/Btn';
import Txt, { TxtProps } from '../ui/Txt';

export type Breadcrumbs = BoxProps<
  'div',
  {
    CtaButtonProps?: BtnStyleProps;
    CtaButtonClass?: string;
    TxtProps?: TxtProps;
    data?: EntryBaseFragment | null;
  }
>;

export default function Breadcrumbs({
  CtaButtonProps,
  CtaButtonClass,
  TxtProps,
  data,
  ...props
}: Breadcrumbs) {
  const { data: page, commonLinks } = useAppContext() ?? {};

  const maybeParent = data?.parent;
  const parent = maybeParent ? parseCommonLink(maybeParent) : commonLinks?.home;
  let ancestors = parent ? [parent] : [];

  switch (true) {
    case isTypedFragment(page, 'article_Entry'):
      if (commonLinks?.articleIndex) ancestors = [commonLinks?.articleIndex];
      break;
    case isTypedFragment(page, 'event_Entry'):
      if (commonLinks?.eventIndex) ancestors = [commonLinks?.eventIndex];
      break;
  }

  return (
    <Box {...props}>
      {ancestors?.map((v, i) => (
        <CtaButton
          key={i}
          href={v.uri ?? ''}
          endIcon={undefined}
          underline="none"
          StartIconProps={{ name: 'chevronLeft' }}
          className={CtaButtonClass}
          {...CtaButtonProps}>
          <Txt {...TxtProps}>{v.title}</Txt>
        </CtaButton>
      ))}
    </Box>
  );
}

import { gql } from '__generated__';

export const EVENT_PRICE_QUERY = gql(`
    query eventPriceEntries {
        eventPriceFieldEntries {
            ...eventPriceCard
        } 
    }
`);

export const EVENT_TIME_QUERY = gql(`
    query eventTimeEntries($date: [QueryArgument]) {
        eventTimeFieldEntries(date: $date, orderBy: "date ASC") {
            ...eventTimeCard
        } 
    }
`);

export const EVENT_INDEX_QUERY = gql(`
    query eventIndexEntries(
        $genreCategory: [QueryArgument],
        $venueCategory: [QueryArgument],
        $accessibilityCategory: [QueryArgument],
        $programCategory: [QueryArgument],
        $eventTypeCategory: [QueryArgument],
        $id: [QueryArgument],
        $orderBy: String
      ) {
        eventEntries(genreCategory: $genreCategory, venueCategory: $venueCategory, accessibilityCategory: $accessibilityCategory, programCategory: $programCategory, eventTypeCategory: $eventTypeCategory, id: $id, orderBy: $orderBy) {
            ...eventCard
        }
    }
`);

import { gql } from '__generated__';

export const ALL_CATEGORIES_QUERY = gql(`
  query categories {
    accessibilityCategory: categories(group: "accessibilityCategory") {
      ...categories
    }
    eventTypeCategory: categories(group: "eventTypeCategory") {
      ...categories
    }
    genreCategory: categories(group: "genreCategory") {
      ...categories
    }
    locationCategory: categories(group: "locationCategory") {
      ...categories
    }
    programCategory: categories(group: "programCategory") {
      ...categories
    }
  }
`);

export const CATEGORY_QUERY = gql(`
  query category($group: [String]) {
    category: categories(group: $group) {
      ...categories
    }
  }
`);

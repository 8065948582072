import { useTranslations } from 'next-intl';
import Btn, { BtnProps } from '../ui/Btn';
import * as styles from './BlockContainer.css';

export type BlockContainerBtnProps = BtnProps<
  'div',
  {
    isActive?: boolean;
    isExpandable?: boolean;
  }
> &
  React.PropsWithChildren;

export const BlockContainerBtn = ({
  children,
  isActive,
  isExpandable,
  ...props
}: BlockContainerBtnProps) => {
  const t = useTranslations('actions');

  if (!isExpandable) return null;

  return (
    <Btn
      className={styles.button}
      fullWidth
      variant="text"
      color="primary"
      underline="always"
      endIcon={isActive ? 'chevronUp' : 'chevronDown'}
      {...props}>
      {children || isActive ? t('showLess') : t('showMore')}
    </Btn>
  );
};

'use client';

import Box from '@/components/ui/Box';
import Txt from '@/components/ui/Txt';
import UnstyledAccordion from '@/components/UnstyledAccordion';
import { FAQ_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { withFragmentArray } from '@liquorice/gql-utils';
import * as styles from './EventLayout.css';

const EventLayoutFaq = withFragmentArray(FAQ_CARD_FRAGMENT, (data) => {
  return (
    <Box cx={{ mY: '3xl' }}>
      <UnstyledAccordion BoxProps={{ cx: { display: 'flex', rowGap: 'sm', direction: 'column' } }}>
        {data.map((faq, index) => {
          return (
            <UnstyledAccordion.Item
              key={index}
              {...{
                index,
                className: styles.accordionItem,
                colorSet: 'primaryTintLighter',
                paper: true,
                HandleProps: {
                  index,
                },
                LabelProps: {
                  children: faq?.heading,
                  color: 'primary',
                  variant: 'text',
                },
                CaretProps: {
                  index,
                },
                BodyProps: {
                  index,
                  children: (
                    <Box cx={{ pX: 'sm' }}>
                      <Txt html>{faq?.content}</Txt>
                    </Box>
                  ),
                },
              }}
            />
          );
        })}
      </UnstyledAccordion>
    </Box>
  );
});

export default EventLayoutFaq;

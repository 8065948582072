import BlockHeader from '@/components/BlockHeader';
import { createBlock } from '@/lib/parsers/blocks';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const EVENT_CREDIT_BLOCK_FRAGMENT = gql(`
  fragment eventCreditBlock on eventCreditItem_Entry {
    title
    heading
  }
`);

export const EVENT_CREDITS_BLOCK_FRAGMENT = gql(`
  fragment eventCreditsBlock on blocks_eventCredits_BlockType {
    heading
    eventCreditItem {
      ...eventCreditBlock
    }
  }
`);

const EventCreditsBlock = createBlock(EVENT_CREDITS_BLOCK_FRAGMENT, ({ data, meta }) => {
  const parseEventCreditItem = createFragmentArrayParser(
    EVENT_CREDIT_BLOCK_FRAGMENT,
    (data) => data
  );
  const eventCredits = parseEventCreditItem(data.eventCreditItem);

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
      }}>
      <BlockHeader
        heading={data.heading}
        HeadingProps={{ cx: { mB: 'xl' }, autoMargin: false, color: 'primary' }}
      />
      {eventCredits?.map((item, index: number) => (
        <BlockHeader
          key={index}
          {...{
            maxWidth: 'none',
            direction: 'row',
            justifyContent: 'between',
            heading: item.title,
            HeadingProps: { variant: 'body', weight: 'bold' },
            content: item.heading,
            ContentProps: { html: false, cx: { width: '50' } },
          }}
        />
      ))}
    </BlockContainer>
  );
});

export default EventCreditsBlock;

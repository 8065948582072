import BlockHeader from '@/components/BlockHeader';
import Carousel from '@/components/Carousel';
import ArticleCard from '@/components/EntryCards/ArticleCard';
import { createBlock } from '@/lib/parsers/blocks';
import { useBreakpointMin } from '@/theme';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const RELATED_NEWS_BLOCK_FRAGMENT = gql(`
  fragment relatedNewsBlock on blocks_relatedNews_BlockType {
    articleMultiple {
      ...articleCard
    }
    heading
  }
`);

const RelatedNewsBlock = createBlock(RELATED_NEWS_BLOCK_FRAGMENT, ({ data, meta }) => {
  const md = useBreakpointMin('md');
  const articles = excludeEmptyTypedElements(data.articleMultiple);

  return (
    <BlockContainer meta={meta} marginY disableTransition>
      <BlockHeader
        maxWidth="none"
        heading={data.heading}
        HeadingProps={{ variant: 'h3', cx: { m: 'none' } }}
        cx={{ mB: 'md' }}
        direction="row"
        alignItems="center"
        justifyContent="between"
      />
      <Carousel
        slidesPerView={md ? 3 : 1}
        slidesPerGroup={md ? 3 : 1}
        spaceBetween={24}
        items={articles.map((v, i) => (
          <ArticleCard key={i} data={v} />
        ))}
      />
    </BlockContainer>
  );
});

export default RelatedNewsBlock;

import BlockHeader from '@/components/BlockHeader';
import Carousel from '@/components/Carousel';
import EventCard from '@/components/EventCard';
import { EVENT_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { createBlock } from '@/lib/parsers/blocks';
import { useBreakpointMin } from '@/theme';
import { createFragmentArrayParser } from '@liquorice/gql-utils';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const RELATED_EVENTS_BLOCK_FRAGMENT = gql(`
  fragment relatedEventsBlock on blocks_relatedEvents_BlockType {
    eventMultiple {
      ...eventCard
    }
    heading
  }
`);

const RelatedEventsBlock = createBlock(RELATED_EVENTS_BLOCK_FRAGMENT, ({ data, meta }) => {
  const md = useBreakpointMin('md');

  const parseEvents = createFragmentArrayParser(EVENT_CARD_FRAGMENT, (data) => data);
  const events = excludeEmptyTypedElements(data.eventMultiple);
  const items = parseEvents(events);

  return (
    <BlockContainer meta={meta} marginY disableTransition>
      <BlockHeader
        maxWidth="none"
        heading={data.heading}
        HeadingProps={{ variant: 'h3', cx: { m: 'none' } }}
        cx={{ mB: 'md' }}
        direction="row"
        alignItems="center"
        justifyContent="between"
      />
      <Carousel
        slidesPerView={md ? 3 : 1}
        slidesPerGroup={md ? 3 : 1}
        spaceBetween={24}
        items={items.map((v, i) => (
          <EventCard key={i} {...v} />
        ))}
      />
    </BlockContainer>
  );
});

export default RelatedEventsBlock;

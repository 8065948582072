import BlockHeader from '@/components/BlockHeader';
import Flex from '@/components/ui/Flex';
import Txt from '@/components/ui/Txt';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';

export const QUOTE_BLOCK_FRAGMENT = gql(`
  fragment quoteBlock on quoteItem_Entry {
    heading 
    label
  }
`);

export const QuoteBlock = createBlock(QUOTE_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading, label } = data;

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
        customAnchor: heading,
      }}>
      <Flex colorSet="white" paper justifyContent="center" alignItems="center" cx={{ pX: 'md' }}>
        <BlockHeader
          maxWidth="none"
          heading={heading}
          HeadingProps={{ variant: 'h3', cx: { textAlign: 'center' } }}>
          <Txt cx={{ textAlign: 'center', width: 'full' }}>{label}</Txt>
        </BlockHeader>
      </Flex>
    </BlockContainer>
  );
});

export default QuoteBlock;

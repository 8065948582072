import { BLOCKS_FRAGMENT } from '@/gql/fragments/blocks.gql';
import { MaskedBlockProps } from '@/lib/parsers/blocks';
import { assertUnreachable } from '@liquorice/utils';
import { getFragmentData } from '__generated__';
import AccordionBlock from './BlockComponents/AccordionBlock';
import CategoryTypeBlock from './BlockComponents/CategoryTypeBlock';
import DividerBlock from './BlockComponents/DividerBlock';
import EntryCardsBlock from './BlockComponents/EntryCardsBlock';
import EventCreditsBlock from './BlockComponents/EventCreditsBlock';
import EventIndexBlock from './BlockComponents/EventIndexBlock';
import EventSearchBlock from './BlockComponents/EventSearchBlock';
import ExpandingCardsBlock from './BlockComponents/ExpandingCardsBlock';
import FeaturedEventBlock from './BlockComponents/FeaturedEventBlock';
import FileDownloadBlock from './BlockComponents/FileDownloadBlock';
import FormBlock from './BlockComponents/FormBlock';
import ImageAndTextBlock from './BlockComponents/ImageAndTextBlock';
import ImageBlock from './BlockComponents/ImageBlock';
import ImageCarouselBlock from './BlockComponents/ImageCarouselBlock';
import LogoAndTextBlock from './BlockComponents/LogoAndTextBlock';
import OverviewCardsBlock from './BlockComponents/OverviewCardsBlock';
import QuoteCarouselBlock from './BlockComponents/QuoteCarouselBlock';
import RelatedEventsBlock from './BlockComponents/RelatedEventsBlock';
import RelatedNewsBlock from './BlockComponents/RelatedNewsBlock';
import RichText from './BlockComponents/RichTextBlock';
import VideoBlock from './BlockComponents/VideoBlock';

export default function BlocksInner<T>(props: MaskedBlockProps<T>) {
  const data = getFragmentData(BLOCKS_FRAGMENT, props.data);

  switch (data.__typename) {
    case 'blocks_richText_BlockType':
      return <RichText {...props} />;
    case 'blocks_accordion_BlockType':
      return <AccordionBlock {...props} />;
    case 'blocks_expandingCards_BlockType':
      return <ExpandingCardsBlock {...props} />;
    case 'blocks_overviewCards_BlockType':
      return <OverviewCardsBlock {...props} />;
    case 'blocks_entryCards_BlockType':
      return <EntryCardsBlock {...props} />;
    case 'blocks_eventIndex_BlockType':
      return <EventIndexBlock {...props} />;
    case 'blocks_categoryType_BlockType':
      return <CategoryTypeBlock {...props} />;
    case 'blocks_eventSearch_BlockType':
      return <EventSearchBlock {...props} />;
    case 'blocks_eventCredits_BlockType':
      return <EventCreditsBlock {...props} />;
    case 'blocks_featuredEvent_BlockType':
      return <FeaturedEventBlock {...props} />;
    case 'blocks_imageAndText_BlockType':
      return <ImageAndTextBlock {...props} />;
    case 'blocks_logoAndText_BlockType':
      return <LogoAndTextBlock {...props} />;
    case 'blocks_imageCarousel_BlockType':
      return <ImageCarouselBlock {...props} />;
    case 'blocks_image_BlockType':
      return <ImageBlock {...props} />;
    case 'blocks_video_BlockType':
      return <VideoBlock {...props} />;
    case 'blocks_quoteCarousel_BlockType':
      return <QuoteCarouselBlock {...props} />;
    case 'blocks_fileDownload_BlockType':
      return <FileDownloadBlock {...props} />;
    case 'blocks_divider_BlockType':
      return <DividerBlock {...props} />;
    case 'blocks_form_BlockType':
      return <FormBlock {...props} />;
    case 'blocks_relatedEvents_BlockType':
      return <RelatedEventsBlock {...props} />;
    case 'blocks_relatedNews_BlockType':
      return <RelatedNewsBlock {...props} />;
    default:
      return assertUnreachable(data);
  }
}

import BlockHeader from '@/components/BlockHeader';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';

export const FORM_BLOCK_FRAGMENT = gql(`
  fragment formBlock on blocks_form_BlockType {
    heading
    content: htmlContentSimple
    form {
      id
    }
  }
`);

export const FormBlock = createBlock(FORM_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { heading, content, form } = data;

  return (
    <BlockContainer
      {...{
        meta,
        disableTransition: true,
        marginY: true,
        customAnchor: heading,
      }}>
      <Grid rowGutter={false} cx={{ rowGap: 'xl' }}>
        <Grid.Col>
          <BlockHeader
            maxWidth="none"
            heading={heading}
            HeadingProps={{ variant: 'h3', color: 'primary', cx: { mB: 'md' } }}
            content={content}
          />
        </Grid.Col>
        <Grid.Col>
          {/* {!!form && <Formie form={form} />} */}
          <p>Form is not working yet!</p>
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default FormBlock;

'use client';

import { MaskedBlockProps } from '@/lib/parsers/blocks';
import React, { createContext, useContext } from 'react';
import { createStore, useStore } from 'zustand';

export const BlockContext = createContext<MaskedBlockProps<unknown> | null>(null);

export const { Provider: BlockProvider } = BlockContext;

export const useBlock = () => {
  return React.useContext(BlockContext) ?? ({} as MaskedBlockProps<unknown>);
};

export interface BlocksContextOptions {
  active?: number[];
  multiple?: boolean;
  disablePaddingStart?: boolean;
  disablePaddingEnd?: boolean;
  disableContainers?: boolean;
  showAnchorMenu?: boolean;
  //   openAll?: boolean;
}

interface BlocksContextState extends BlocksContextOptions {
  setActive: (index: number | number[]) => void;
  addActive: (index: number) => void;
  removeActive: (index: number) => void;
}

export type BlocksContextStore = ReturnType<typeof createBlocksContextStore>;

export const createBlocksContextStore = (initProps?: Partial<BlocksContextOptions>) => {
  return createStore<BlocksContextState>()((set, get) => ({
    active: [],
    setActive: (index) => {
      const multiple = get().multiple;
      const activeArr = Array.isArray(index) ? index : [index];
      const active = multiple ? activeArr : [activeArr[activeArr.length - 1]];

      set({ active });
    },
    addActive: (index) => {
      const active = get().active ?? [];
      if (active.indexOf(index) === -1) {
        active.push(index);
        set({ active });
      }
    },
    removeActive: (index) => {
      const active = get().active ?? [];
      const indexToRemove = active.indexOf(index);
      if (indexToRemove !== -1) {
        active.splice(indexToRemove, 1);
        set({ active });
      }
    },
    multiple: false,
    ...initProps,
  }));
};

export const BlocksContextContext = createContext<BlocksContextStore | null>(null);

export function useBlocksContext<T>(selector: (state: BlocksContextState) => T): T {
  const store = useContext(BlocksContextContext);
  if (!store) throw new Error('Missing BlocksContextContext.Provider in the tree');
  return useStore(store, selector);
}

export const useBlocksContextItem = (index?: number) => {
  const { active, setActive } = useBlocksContext((state) => state);
  if (index === undefined) return { isActive: false, toggle: () => {} };

  const isActive = active?.indexOf(index) !== -1;
  const toggle = (force?: boolean) => {
    const newActive = force ?? !isActive;
    if (newActive) {
      setActive(index);
    } else {
      setActive([]);
    }
  };
  return { isActive, toggle, active };
};

import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { gql } from '__generated__';
import { BlockContainer } from '../../BlockContainer';
import ExpandingCardBlock from '../ExpandingCardBlock';
import ExternalCardBlockExpanding from '../ExternalCardBlock/ExternalCardBlock.Expanding';

export const EXPANDING_CARDS_BLOCK_FRAGMENT = gql(`
  fragment expandingCardsBlock on blocks_expandingCards_BlockType {
    expandCardMultiple {
      ...expandingCardBlock
      ...externalCardBlock
    }
  }
`);

export const ExpandingCardsBlock = createBlock(EXPANDING_CARDS_BLOCK_FRAGMENT, ({ data, meta }) => {
  const items = excludeEmptyTypedElements(data.expandCardMultiple);

  return (
    <BlockContainer {...{ meta, marginY: true, disableTransition: true }}>
      <Grid cx={{ rowGap: '3xl' }}>
        {items?.map((item, i: number) => {
          return (
            <Grid.Col key={i}>
              {item.__typename === 'expandCard_Entry' ? (
                <ExpandingCardBlock data={item} />
              ) : (
                <ExternalCardBlockExpanding data={item} />
              )}
            </Grid.Col>
          );
        })}
      </Grid>
    </BlockContainer>
  );
});

export default ExpandingCardsBlock;

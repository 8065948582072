import Carousel from '@/components/Carousel';
import Image from '@/components/Image';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const IMAGE_CAROUSEL_BLOCK_FRAGMENT = gql(`
  fragment imageCarouselBlock on blocks_imageCarousel_BlockType {
    imageMultiple {
      ...image
    }
  }
`);

const ImageCarouselBlock = createBlock(IMAGE_CAROUSEL_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { imageMultiple } = data;

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
        overflow: true,
      }}>
      <Carousel
        spaceBetween={24}
        items={imageMultiple?.map((v, i) => (
          <Image key={i} data={v} ratio="landscape" rounded={false} captions={true} />
        ))}
      />
    </BlockContainer>
  );
});

export default ImageCarouselBlock;

'use client';
import { PropsWithChildren, useRef } from 'react';
import {
  BlocksContextContext,
  BlocksContextOptions,
  BlocksContextStore,
  createBlocksContextStore,
} from './useBlock';

export interface BlocksContextProviderProps extends BlocksContextOptions, PropsWithChildren {
  openFirst?: boolean;
}

const BlocksContextProvider = ({ children, ...props }: BlocksContextProviderProps) => {
  const storeRef = useRef<BlocksContextStore>();
  if (!storeRef.current) {
    storeRef.current = createBlocksContextStore(props);
  }
  return (
    <BlocksContextContext.Provider value={storeRef.current}>
      {children}
    </BlocksContextContext.Provider>
  );
};

export default BlocksContextProvider;

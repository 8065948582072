'use client';

import Card, { CardProps } from '@/components/Card';
import Box from '@/components/ui/Box';
import Flex from '@/components/ui/Flex';
import { ColorScheme, useColorScheme } from '@/lib/parsers/common';
import { useBreakpointMax } from '@/theme';
import Grid from '../ui/Grid';

export type OverviewCardProps = CardProps & {
  flipAlignment?: boolean;
  colorScheme?: ColorScheme;
};

const OverviewCard = ({ item, colorScheme, ...props }: OverviewCardProps) => {
  const { href, title, description, image } = item;
  const { colorScheme: colorSet, oppositeScheme } = useColorScheme(colorScheme);

  const md = useBreakpointMax('md');

  return (
    <Card
      colorSet={colorSet}
      paper
      elevation={false}
      item={{
        title,
        description,
        image,
        href,
      }}
      {...props}>
      <Grid>
        {md && (
          <Grid.Col>
            <Card.Image ratio="square" />
          </Grid.Col>
        )}
        <Grid.Col md={7}>
          <Flex cx={{ p: 'lg' }} rowGap="md">
            <Card.Title as="span" />
            <Card.Description variant="large" color={oppositeScheme} />
            <Box cx={{ width: '50' }}>
              <Card.Cta color={oppositeScheme} variant="filled" size="large" fullWidth />
            </Box>
          </Flex>
        </Grid.Col>
        {!md && (
          <Grid.Col offsetMd={1} md={3}>
            <Card.Image mask />
          </Grid.Col>
        )}
      </Grid>
    </Card>
  );
};

export default OverviewCard;

'use client';

import Image, { ImageAssetProps } from '../Image';
import VideoBackground, { VideoBackgroundProps } from '../Video/VideoBackground';

export type PageMediaProps = {
  ImageProps?: ImageAssetProps;
  VideoProps?: VideoBackgroundProps;
};

export default function PageMedia({ ImageProps, VideoProps }: PageMediaProps) {
  const { data, ...restImageProps } = ImageProps ?? {};
  const { videoUrl, ...restVideoProps } = VideoProps ?? {};

  return videoUrl ? (
    <VideoBackground videoUrl={videoUrl} {...restVideoProps} />
  ) : data ? (
    <Image alt="page-image" priority data={data} {...restImageProps} />
  ) : null;
}

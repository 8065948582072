'use client';

import Blocks from '@/components/Blocks';
import { createToggleStore } from '@/lib/utils/createToggleStore';
import { excludeEmptyTypedElements } from '@liquorice/utils';
import { EventEntryFragment } from '__generated__/graphql';
import EventLayoutDivider from './EventLayout.Divider';
import EventLayoutFaq from './EventLayout.Faq';

export const useEventFAQ = createToggleStore(false, 'devMode');

const EventLayoutBody = ({ blocks, faqMultiple }: EventEntryFragment) => {
  const { value: eventFaqEnabled } = useEventFAQ();
  const faqs = excludeEmptyTypedElements(faqMultiple);

  return (
    <>
      {eventFaqEnabled ? <EventLayoutFaq data={faqs} /> : <Blocks key="blocks" blocks={blocks} />}
      <EventLayoutDivider key="divider" />
    </>
  );
};

export default EventLayoutBody;

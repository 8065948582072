import ImageWithCaption from '@/components/ImageWithCaption';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const IMAGE_BLOCK_FRAGMENT = gql(`
  fragment imageBlock on blocks_image_BlockType {
    imageSingle {
      ...image
    }
    caption
  }
`);

const ImageBlock = createBlock(IMAGE_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { imageSingle, caption } = data;

  return (
    <BlockContainer meta={meta} marginY disableTransition customAnchor={caption}>
      <ImageWithCaption
        imageProps={{
          data: imageSingle,
          rounded: false,
          fixedRatio: true,
          // ratio: 'landscape',
        }}
        caption={caption}
      />
    </BlockContainer>
  );
});

export default ImageBlock;

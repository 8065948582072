import Video from '@/components/Video';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';
import { BlockContainer } from '../..';

export const VIDEO_BLOCK_FRAGMENT = gql(`
  fragment videoBlock on blocks_video_BlockType {
    videoUrl
  }
`);

const VideoBlock = createBlock(VIDEO_BLOCK_FRAGMENT, ({ data, meta }) => {
  const { videoUrl } = data;

  return (
    <BlockContainer
      {...{
        meta,
        marginY: true,
        disableTransition: true,
      }}>
      <Video videoUrl={videoUrl} />
    </BlockContainer>
  );
});

export default VideoBlock;

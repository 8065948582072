import BasicCard from '@/components/BasicCard';
import { createBlock } from '@/lib/parsers/blocks';
import { gql } from '__generated__';
import { useSimpleCard } from './useSimpleCard';

export const SIMPLE_CARD_BLOCK_FRAGMENT = gql(`
  fragment simpleCardBlock on entryCard_Entry {
    entrySingle {
      ...entryCards
    }
    
    # Override props
    heading
    content: htmlContentSimple
    linkField {
      ...linkField
    }
  }
`);

export const SimpleCardBlock = createBlock(SIMPLE_CARD_BLOCK_FRAGMENT, ({ data }) => {
  if (!data) return null;
  const item = useSimpleCard(data);

  if (!item) return null;

  return (
    <BasicCard
      {...{
        disableLinkWrap: true,
        item,
      }}
    />
  );
});

export default SimpleCardBlock;

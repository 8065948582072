'use client';

// import { useViewColorScheme } from '@/lib/store/hooks';
import { ENTRY_BASE_FRAGMENT } from '@/gql/fragments/entries.gql';
import { PAGE_STANDARD_CARD_FRAGMENT } from '@/gql/fragments/entryCards.gql';
import { useColorScheme } from '@/lib/parsers/common';
import { useBreakpointMin } from '@/theme';
import { withFragment } from '@liquorice/gql-utils';
import { getFragmentData } from '__generated__';
import Breadcrumbs from '../Breadcrumbs';
import Grid from '../ui/Grid';
import PageHeaderRoot from './PageHeaderRoot';
import PageImage from './PageImage';
import PageSummary from './PageSummary';
import PageTitle from './PageTitle';

const PageHeaderStandard = withFragment(PAGE_STANDARD_CARD_FRAGMENT, (data) => {
  const base = getFragmentData(ENTRY_BASE_FRAGMENT, data);
  const sm = useBreakpointMin('sm');
  const { colorScheme, oppositeScheme } = useColorScheme(data?.colorScheme);

  if (!data) return null;

  return (
    <PageHeaderRoot
      variant="standard"
      colorSet={colorScheme}
      paper
      GridColProps={{ xs: 12, sm: 7, md: 8, cx: { mY: '5xl' } }}
      afterCol={
        sm && (
          <Grid.Col offsetMd={1} sm={5} md={3}>
            <PageImage mask={true} alt="page-image" data={data.entryImage} />
          </Grid.Col>
        )
      }>
      <Breadcrumbs data={base} CtaButtonProps={{ color: oppositeScheme }} cx={{ mB: 'xs' }} />
      <PageTitle monoFont uppercase cx={{ mB: 'xs' }}>
        {data.entryTitle}
      </PageTitle>
      <PageSummary weight="bold" useGridCol={false}>
        {data.entrySummary}
      </PageSummary>
    </PageHeaderRoot>
  );
});

export default PageHeaderStandard;

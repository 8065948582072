import BlockHeader from '@/components/BlockHeader';
import { BlockContainer } from '@/components/Blocks';
import CtaButton from '@/components/CtaButton';
import Image from '@/components/Image';
import Box from '@/components/ui/Box';
import Grid from '@/components/ui/Grid';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkField } from '@/lib/parsers/common';
import { gql } from '__generated__';

export const IMAGE_AND_TEXT_BLOCK_FRAGMENT = gql(`
  fragment imageAndTextBlock on blocks_imageAndText_BlockType {
    heading
    content: htmlContentDefault
    linkField {
      ...linkField
    }
    flipAlignment
    imageSingle {
      ...image
    }
    lightswitch
  }
`);

const ImageAndTextBlock = createBlock(IMAGE_AND_TEXT_BLOCK_FRAGMENT, ({ data, meta }) => {
  const {
    heading,
    content,
    linkField,
    flipAlignment,
    imageSingle,
    lightswitch: quarterImage,
  } = data;

  const link = parseLinkField(linkField);

  return (
    <BlockContainer {...{ meta, disableTransition: true, marginY: true, customAnchor: heading }}>
      <Grid
        cx={{
          alignItems: 'center',
          justifyContent: 'between',
          flexDirection: { md: flipAlignment ? 'row-reverse' : 'row' },
        }}>
        <Grid.Col md={quarterImage ? 9 : 6}>
          <BlockHeader
            cx={{ p: '2xs' }}
            maxWidth="none"
            heading={heading}
            HeadingProps={{ variant: 'h5', color: 'primary', cx: { mB: 'md' } }}
            content={content}>
            {link && (
              <CtaButton
                variant="filled"
                size="large"
                rounded="small"
                endIcon={undefined}
                {...link}
                cx={{ mT: 'md' }}
              />
            )}
          </BlockHeader>
        </Grid.Col>
        <Grid.Col md={quarterImage ? 3 : 6}>
          <Box cx={{ p: '2xs' }}>
            <Image alt="" data={imageSingle} />
          </Box>
        </Grid.Col>
      </Grid>
    </BlockContainer>
  );
});

export default ImageAndTextBlock;

import ExpandingCard from '@/components/ExpandingCard';
import { createBlock } from '@/lib/parsers/blocks';
import { parseLinkField } from '@/lib/parsers/common';
import { cleanHtml } from '@/lib/utils/htmlHelpers';
import { toStringOrNull } from '@liquorice/utils';
import { EXTERNAL_CARD_BLOCK_FRAGMENT } from './useExternalCard';

export const ExternalCardBlockExpanding = createBlock(EXTERNAL_CARD_BLOCK_FRAGMENT, ({ data }) => {
  const { heading, content, linkField, imageSingle, colorScheme } = data;
  const link = parseLinkField(linkField);
  const href = link?.href;
  const newWindow = link?.target === '_blank';
  const title = toStringOrNull(heading);
  const description = cleanHtml(content);

  return (
    <ExpandingCard
      {...{
        item: {
          title,
          description,
          image: imageSingle,
          href,
        },
        colorScheme,
        newWindow,
      }}
    />
  );
});

export default ExternalCardBlockExpanding;
